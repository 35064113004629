import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { useNavigate,useLocation } from 'react-router-dom';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import config from './config';
import { encode } from 'html-entities';

const PageEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [editorHtml, setEditorHtml] = useState('');
  const [encodeHtml, setEncodeHtml] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewHtml, setPreviewHtml] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [pageData, setPageData] = useState({
    title: '',
    metaDescription: '',
    metaKeyword: '',
    canonicalTag: '',
    ogTag: '',
    schemaMarkup: '',
    customurl:'',
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
      if (!validTypes.includes(file.type)) {
        alert('Invalid file type. Only JPEG, PNG, GIF, and SVG files are allowed.');
        return;
      }
      setImageFile(file);
    }
  };


  const handleChange = (e) => {
    setPageData({ ...pageData, [e.target.name]: e.target.value });
    if (validationErrors[e.target.name]) {
      setValidationErrors({ ...validationErrors, [e.target.name]: '' });
    }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    const html = editor.getHTML();
    const encodedHtml = encode(html);
    setEncodeHtml(encodedHtml);
    setEditorHtml(html);
  };

  useEffect(() => {
    if (location.state && location.state.pageid) {
      console.log('have the data');
      const fetchCategoryDetails = async () => {
        const response = await fetch(`${config.apiBaseUrl}/api/page/detail/${location.state.pageid}`);
        const data = await response.json();
        
        if (response.ok) {
          console.log('data ddd ',JSON.stringify(data));
          setPageData({
            id:data.data.id,
            title: data.data.title,
            metaDescription: data.data.metaDescription,
            metaKeyword: data.data.metaKeyword,
            canonicalTag: data.data.canonicalTag,
            ogTag: data.data.ogTag,
            schemaMarkup: data.data.schemaMarkup,
            content: data.data.pagcontent,
            customurl:data.data.customurl,
            imagePath:data.data.imagePath
          });
          setEditorHtml(data.data.pagcontent);
          setIsEdit(true);
        }
      };
      fetchCategoryDetails();
    }
  }, [location.state]);
  
  const generateHTML = (data, content) => `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${data.title}</title>
        <meta name="description" content="${data.metaDescription}">
        <meta name="keywords" content="${data.metaKeyword}">
        ${data.canonicalTag}
        ${data.ogTag}

        <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
        <style>
          body { margin: 0; padding: 0; }
          .container { padding: 20px; }
          .header { background: #f8f9fa; padding: 20px; text-align: center; }
          .header img { max-height: 50px; }
          .header .btn { margin-top: 10px; }
          .content { margin-top: 20px; }
          .footer { background: #f8f9fa; padding: 20px; text-align: center; margin-top: 20px; }
          .footer .social-icons img { width: 30px; margin: 0 10px; }
          img.banner {
            width: 80%;
            height: auto;
            display: block;
            margin: 0 auto 20px;
          }
        </style>
    </head>
    <body>
        <div class="header">
            <img src="/black-logo.png" alt="Logo">
            <h1>${data.title}</h1>
            <a href="https://officekithr.com/" class="btn btn-danger">Back to website</a>
        </div>
        ${data.imagePath ? `<img src="${data.imagePath}" alt="Banner" class="banner">` : ''}
        <div class="container content">
            ${content}
        </div>
        <div class="footer">
          <h5>Officekithr</h5>
        </div>
    </body>
    </html>
  `;

  const handlePreview = () => {
    const fullHtml = generateHTML(pageData, editorHtml);
    // Open preview dialog
      setPreviewHtml(fullHtml);
      setPreviewOpen(true);
  };

  const closePreview = () => {

      setPreviewOpen(false);

  };

  const validateInputs = () => {
    let errors = {};
    if (!pageData.title.trim()) {
      errors.title = 'Title is required.';
    }
    if (!pageData.metaDescription.trim()) {
      errors.metaDescription = 'Meta description is required.';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }


    try {
      let imagePath='';
      if(pageData.imagePath) imagePath = pageData.imagePath;
      if (imageFile) {
        const formData = new FormData();        
        formData.append('avatar',imageFile)
        const url = `${config.apiBaseUrl}/upload.php`;
        const responses = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        const result = await responses.json();
        console.log('imgae upload',result);
        if (result.url) {
          imagePath = result.url;
        }
      }

      const formData = {
        ...pageData,
        content: editorHtml, //encodeHtml
        imagePath:imagePath,
      };
      console.log('JSON.stringify(formData)',JSON.stringify(formData));
      const url = isEdit ? `${config.apiBaseUrl}/api/page/update` : `${config.apiBaseUrl}/api/page/save`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      const result = await response.json();
      console.log('ssssssssss',result);
      if (result.status) {
        const message = result.status ? (isEdit ? 'Page updated successfully!' : 'Page added successfully!') : 'Failed to process!';
        navigate('/products', { state: { message: message, severity: result.status ? 'success' : 'error' } });
      } else {
        alert('Failed to save the page');
      }  
    } catch (error) {
      alert('Error:', error);
    }
  };

  const handleRemoveImage = () => {
    setPageData({ ...pageData, imagePath: '' });
  };

  return (
    <Box>
      <Box component="form" noValidate autoComplete="off">
      <TextField 
        label="Page Title" 
        name="title" fullWidth 
        margin="normal" 
        value={pageData.title} 
        onChange={handleChange} 
        error={!!validationErrors.title}
        helperText={validationErrors.title || ''}
      />
      <TextField 
      label="Meta Description" 
      name="metaDescription" fullWidth multiline 
      margin="normal" 
      value={pageData.metaDescription} 
      onChange={handleChange}
      error={!!validationErrors.metaDescription}
      helperText={validationErrors.metaDescription || ''}
      />
      <TextField label="Meta Keyword" name="metaKeyword" fullWidth margin="normal" value={pageData.metaKeyword} onChange={handleChange} />
      <TextField label="Canonical Tag" name="canonicalTag" fullWidth margin="normal" value={pageData.canonicalTag} onChange={handleChange} />
      <TextField label="OG Tag" name="ogTag" fullWidth margin="normal" value={pageData.ogTag} onChange={handleChange} />
      <TextField label="Schema Markup" name="schemaMarkup" fullWidth multiline margin="normal" value={pageData.schemaMarkup} onChange={handleChange} />
      <TextField label="Custom Url" name="customurl" fullWidth multiline margin="normal" value={pageData.customurl} onChange={handleChange} />
      {pageData.imagePath && (
        <Box sx={{ my: 2 }}>
          <Typography variant="body1">Current Image:</Typography>
          <img src={pageData.imagePath} alt="Uploaded" style={{ maxWidth: '25%', height: 'auto' }} />
          <Button variant="contained" color="error" onClick={handleRemoveImage} sx={{ mt: 1 }}>
            Remove Image
          </Button>
        </Box>
      )}
      <TextField
        label="Upload Image"
        type="file"
        InputLabelProps={{ shrink: true }}
        fullWidth
        margin="normal"
        onChange={handleImageChange}
      />
      <ReactQuill
        value={editorHtml}
        onChange={handleEditorChange}
        modules={PageEditor.modules}
        formats={PageEditor.formats}
        theme="snow"
      /><br /><br />
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save Page
        </Button>&nbsp;
          <Button onClick={handlePreview} variant="contained" color="primary">
            Preview
        </Button>
        <Dialog open={previewOpen} onClose={closePreview} maxWidth="lg" fullWidth>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closePreview} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
        </Box>
        </Box>
  );
};

PageEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']                                         
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};

PageEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default PageEditor;
