// StyledComponents.js
import { styled } from '@mui/system';
import { TableRow, TableHead } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#e8edee',
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#A9D3F7',
  '& .MuiTableCell-head': {
    color: '#1F415E',
    fontWeight: 'bold',
    textAlign: 'left',
  },
}));
