import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography,  Menu, MenuItem, Button } from '@mui/material';
import { AuthContext } from './context/AuthContext';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { Link, useNavigate } from 'react-router-dom';

function AppBarComponent({ toggleDrawer }) {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton> */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          WITSDYNAMICS
        </Typography>
        <Button color="inherit" component={Link} to="/dashboard" >Dashboard</Button>
        <Button color="inherit" component={Link} to="/products" >All pages</Button>
        <Button color="inherit" onClick={handleMenuOpen}><PersonSharpIcon/></Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>My account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;


