import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ProductionQuantityLimitsSharpIcon from '@mui/icons-material/ProductionQuantityLimitsSharp';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

function DrawerComponent({ open, onClose }) {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="temporary"
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <List>
          <ListItem button component={Link} to="/products">
            <ListItemIcon>
              <ProductionQuantityLimitsSharpIcon />
            </ListItemIcon>
            <ListItemText primary="All Pages" />
          </ListItem>
      </List>
    </Drawer>
  );
}

export default DrawerComponent;
