import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import config from './config';

const PagePreview = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/page/detail/${id}`);
        const data = await response.json();
        setPageData(data.data);
      } catch (error) {
        console.error('Error fetching page data:', error);
      }
    };
    fetchData();
  }, [id, location]);

  if (!pageData) {
    return <div>Loading...</div>;
  }

  const extractHrefFromCanonicalTag = (canonicalTag) => {
    const match = canonicalTag.match(/href="([^"]*)"/);
    return match ? match[1] : canonicalTag;
  };

  const canonicalHref = extractHrefFromCanonicalTag(pageData.canonicalTag);

  const parseOgTags = (ogTagString) => {
    const ogTagRegex = /<meta property="([^"]+)" content="([^"]+)"\s*\/?>/g;
    const ogTags = [];
    let match;

    while ((match = ogTagRegex.exec(ogTagString)) !== null) {
      const property = match[1];
      const content = match[2];
      ogTags.push(<meta key={property} property={property} content={content} />);
    }

    return ogTags;
  };

  const ogTags = parseOgTags(pageData.ogTag);
  return (
    <div>
      <Helmet>
        <title>{pageData.title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={pageData.metaDescription} />
        <meta name="keywords" content={pageData.metaKeyword} />
        {ogTags}
        <meta property="og:title" content={pageData.ogTag} />
        <meta dangerouslySetInnerHTML={{ __html: pageData.ogTag }} />
        <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet" />
        <link rel="canonical" href={canonicalHref} />
        <script type="application/10+json" dangerouslySetInnerHTML={{ __html: pageData.schemaMarkup }} />       
        <body className="custom-body-class" style={{ backgroundColor: '#f0f0f0' }} />
      </Helmet>

      <div className="header">
        <img src="/black-logo.png" alt="Logo" />
        <h1>{pageData.title}</h1>
        <a href="https://officekithr.com/" className="btn btn-danger">Back to website</a>
      </div>

      {pageData.imagePath && (
        <img src={pageData.imagePath} alt="Banner" className="banner" />
      )}

      <div className="container content" dangerouslySetInnerHTML={{ __html: pageData.pagcontent }} />

      <div className="footer">
        <h5>Officekithr</h5>
      </div>
      <style>{`
        body { margin: 0; padding: 0; }
        .container { padding: 20px; }
        .header { background: #f8f9fa; padding: 20px; text-align: center; }
        .header img { max-height: 50px; }
        .header .btn { margin-top: 10px; }
        .content { margin-top: 20px; }
        .footer { background: #f8f9fa; padding: 20px; text-align: center; margin-top: 20px; }
        .footer .social-icons img { width: 30px; margin: 0 10px; }
        img.banner {
          width: 80%;
          height: auto;
          display: block;
          margin: 0 auto 20px;
        }
      `}</style>
    </div>
  );
};

export default PagePreview;
