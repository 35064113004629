import React from 'react';
import './Dashboard.css'; // Assuming you will use CSS for styling

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="job-views">
        <h2>Total Page Count</h2>
        <div>0</div>
      </div>
      <div className="active-jobs">
        <h2>Active Users</h2>
        <div>0</div>
      </div>
    </div>
  );
};

export default Dashboard;
