import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Snackbar, Alert, Typography, Box, Button, 
  Table, TableBody, TableCell, TableContainer, TableRow, 
  Paper, IconButton, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, TextField, Pagination } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StyledTableRow, StyledTableHead } from './StyledComponents';
import config from './config';

const ProductList = () => {
  const [products, setproducts] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredProducts = products.filter((product) =>
    Object.values(product).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedProducts = filteredProducts.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/api/page/getlist`)
      .then((res) => {
        if(!res.ok){
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        console.log('data',data);
        if (Array.isArray(data.data)) {
          setproducts(data.data);
        }else {
          setproducts([]);
        }
      })
      .catch((error) => console.error('Error fetching pages:', error));
      
    // Check for state passed via navigate
    if (location.state?.message) {
      setSnackbarMessage(location.state.message);
      setAlertSeverity(location.state.severity || 'info');
      setOpenSnackbar(true);
    }
  }, [location.state]);

  const handleCreateNew = () => {
    navigate('/pageedit');
    
  };

  const handleEdit = (id) => {
    navigate('/pageedit', { state: { pageid: id } });
  };

  const handleDelete = async (id) => {
    try {
      console.log('delete data:', JSON.stringify(id));
        const response = await fetch(`${config.apiBaseUrl}/api/page/delete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: id })
        });
        const result = await response.json();
        if (response.ok) {
            console.log('delete successful:', result);
            if(result.status){
                setSnackbarMessage('Delete Data successfully!');
                setAlertSeverity('success');
                setproducts(prevProducts => prevProducts.filter(product => product.id !== id));
            } else {
                setSnackbarMessage('Failed to Delete data!');
                setAlertSeverity('error');
            }
            setOpenSnackbar(true);
        } else {
            console.error('HTTP error:', response.status, await response.text());
            setSnackbarMessage(`Failed to Delete data: ${response.status}`);
            setAlertSeverity('error');
            setOpenSnackbar(true);
        }
    } catch (error) {
        console.error('Error Delete data:', error);
        setSnackbarMessage(`Error Delete data! ${error.message}	`);
        setAlertSeverity('error');
        setOpenSnackbar(true);
    }
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const showDialog = (id) => {
    setCurrentId(id);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    handleDelete(currentId);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
        <Typography variant="h4">All Pages</Typography>
    <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" onClick={handleCreateNew} sx={{ mr: 2 }}>
            Create New Page
          </Button>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell>Sl.No</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Page Tile</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
          {products.length > 0 ? (
            paginatedProducts.map((product,index) => (
              <StyledTableRow key={product.id}>
                <TableCell>{index+1}</TableCell>
                <TableCell>{product.ccdate}</TableCell>
                <TableCell>{product.title}</TableCell>
                <TableCell>{product.metaDescription}</TableCell>
                <TableCell>
                {product.customurl ? (
                <IconButton color="secondary" onClick={() => window.open(`${product.customurl}/${product.id}`, '_blank')}>
                  <VisibilityIcon /> 
                </IconButton>
                ) : (
                  <IconButton color="secondary" onClick={() => window.open(`/preview/${product.id}`, '_blank')}>
                  <VisibilityIcon /> 
                  </IconButton>
                )}
                <IconButton color="primary" onClick={() => handleEdit(product.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => showDialog(product.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={12} align="center">No data found</TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" my={2}>
        <Pagination
          count={Math.ceil(filteredProducts.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Page details?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      {openSnackbar && (
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ProductList;
