import React, { useState, useContext,useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, Container, Box } from '@mui/material';
import { AuthProvider, AuthContext } from './context/AuthContext';
import AppBarComponent from './AppBarComponent';
import DrawerComponent from './DrawerComponent';
import Login from './login/Login';
import Product from './ProductList';
import PageEditor from './PageEditor';
import PagePreview from './PagePreview';
import Dashboard from './Dashboard';
// import PreviewLayout from './PreviewLayout';
import config from './config';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    return isAuthenticated ? children : <Navigate to="/login" />;
};

const Layout = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    // const isPreviewRoute = location.pathname.startsWith('/preview');
    const showComponents = location.pathname.startsWith('/products') || location.pathname.startsWith('/pageedit') || location.pathname.startsWith('/dashboard');
    return (
        <>
            {showComponents && (
                <>
                    <PrivateRoute><AppBarComponent toggleDrawer={toggleDrawer} /></PrivateRoute>
                    <DrawerComponent open={drawerOpen} onClose={toggleDrawer} />
                </>
            )}
            <Container maxWidth={false} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Box sx={{ flexGrow: 1, m: 4 }}>
                    {children}
                </Box>
            </Container>
        </>
    );
};

function App() {
    const [dynamicRoutes, setDynamicRoutes] = useState([]);
    useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`${config.apiBaseUrl}/api/page/getCustomlist`);
              const data = await response.json();
              console.log('data.data',data.data);
              setDynamicRoutes(data.data);
            } catch (error) {
              console.error('Error fetching page data:', error);
            }
          };
          fetchData();
    }, []);

    return (
        <AuthProvider>
            <Router>
                <CssBaseline />
                <Layout>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                        <Route path="/products" element={<PrivateRoute><Product /></PrivateRoute>} />
                        <Route path="/pageedit" element={<PrivateRoute><PageEditor /></PrivateRoute>} />
                        <Route path="/preview/:id" element={<PagePreview />} />
                        {Array.isArray(dynamicRoutes) && dynamicRoutes.length > 0 && dynamicRoutes.map(route => (
                            <Route
                                key={route.customurl}
                                path={`${route.customurl.startsWith('/') ? '' : '/'}${route.customurl}/:id`}
                                element={<PagePreview />}
                            />
                        ))}
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
}

export default App;
