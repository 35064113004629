const dev = {
  apiBaseUrl: 'http://localhost/seo_app',
  // Add other development specific values here
};

const prod = {
  apiBaseUrl: 'https://service.appcloudconsole.com',
  // Add other production specific values here
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
 